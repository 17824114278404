<template>
  <mailchimp
    audience="gradudated"
    user-id="6da7338b25bfc6200660bdc63"
    list-id="83749daa32"
    signup="newsletter"
    @error="onError"
    @success="onSuccess"
  >
    <template v-slot="{ subscribe, setEmail, error, success }">
      <!-- loading -->
      <div class="fixed bg-gray-900 inset-x-0 bottom-0 z-50">
        <form @submit.prevent="subscribe">
          <transition name="fade">
            <div
              v-if="show"
              class="w-full text-white fade-enter-active fade-leave-active text-center m-1"
            >
              <div v-if="!success && !error">
                <span class="p-3 mr-3">Want job matches emailed weekly?</span>
                <input
                  class="text-gray-700 rounded w-64 p-2 h-full"
                  type="text"
                  placeholder="your@email.com"
                  @input="setEmail($event.target.value)"
                >
                <button
                  class="ml-2 focus:shadow-outline focus:outline-none text-white py-2 px-4 shadow rounded"
                  style="background-color: #44B244"
                  type="submit"
                >
                  Signup
                </button>
              </div>
              <div
                v-if="error"
                class="p-3 mr-3"
              >
                <span v-html="error" />
              </div>
              <div
                v-if="success"
                class="p-3 mr-3"
              >
                <span>Thank you for subscribing!</span>
              </div>
              <!--<div v-if="loading" class="opacity-50 p-3 mr-3">
                <span>&nbsp;</span>
              </div>-->
            </div>
          </transition>
        </form>
      </div>
    </template>
  </mailchimp>
</template>

<script>
import Mailchimp from "../common/mailchimp";

export default {
  name: "NewsLetterSignup",
  components: {
    Mailchimp,
  },
  data() {
    return {
      scrollpx: 0,
    };
  },
  computed: {
    show() {
      return this.scrollpx > 300;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
    onError() {
      console.log("onError");
    },
    onSuccess() {
      console.log("onSuccess");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
