var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mailchimp", {
    attrs: {
      audience: "gradudated",
      "user-id": "6da7338b25bfc6200660bdc63",
      "list-id": "83749daa32",
      signup: "newsletter"
    },
    on: { error: _vm.onError, success: _vm.onSuccess },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var subscribe = ref.subscribe
          var setEmail = ref.setEmail
          var error = ref.error
          var success = ref.success
          return [
            _c(
              "div",
              { staticClass: "fixed bg-gray-900 inset-x-0 bottom-0 z-50" },
              [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return subscribe($event)
                      }
                    }
                  },
                  [
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.show
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-full text-white fade-enter-active fade-leave-active text-center m-1"
                            },
                            [
                              !success && !error
                                ? _c("div", [
                                    _c("span", { staticClass: "p-3 mr-3" }, [
                                      _vm._v("Want job matches emailed weekly?")
                                    ]),
                                    _c("input", {
                                      staticClass:
                                        "text-gray-700 rounded w-64 p-2 h-full",
                                      attrs: {
                                        type: "text",
                                        placeholder: "your@email.com"
                                      },
                                      on: {
                                        input: function($event) {
                                          return setEmail($event.target.value)
                                        }
                                      }
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "ml-2 focus:shadow-outline focus:outline-none text-white py-2 px-4 shadow rounded",
                                        staticStyle: {
                                          "background-color": "#44B244"
                                        },
                                        attrs: { type: "submit" }
                                      },
                                      [_vm._v(" Signup ")]
                                    )
                                  ])
                                : _vm._e(),
                              error
                                ? _c("div", { staticClass: "p-3 mr-3" }, [
                                    _c("span", {
                                      domProps: { innerHTML: _vm._s(error) }
                                    })
                                  ])
                                : _vm._e(),
                              success
                                ? _c("div", { staticClass: "p-3 mr-3" }, [
                                    _c("span", [
                                      _vm._v("Thank you for subscribing!")
                                    ])
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }