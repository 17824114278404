var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "cursor-pointer bg-white rounded pt-8 shadow hover:shadow-lg hover:bg-gray-100",
      on: { click: _vm.showCompanyPage }
    },
    [
      _c("div", { staticClass: "flex justify-center " }, [
        _c("img", {
          staticClass: "h-32 w-32 rounded-full border border-1 shadow-large",
          attrs: { src: "/logos/" + _vm.company.slug.toLowerCase() + ".png" }
        })
      ]),
      _c("div", { staticClass: "text-center px-3 pb-6 pt-2" }, [
        _c(
          "h3",
          { staticClass: "text-black text-lg bold font-sans font-bold" },
          [_vm._v(" " + _vm._s(_vm.company.name) + " ")]
        ),
        _c("p", { staticClass: "mt-2 font-sans font-light text-grey-dark" }, [
          _vm._v(" " + _vm._s(_vm.company.message) + " ")
        ])
      ]),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex bg-gray-300 justify-center text-grey-dark border-t-1 rounded-b py-4"
      },
      [
        _c("div", { staticClass: "text-center mr-3 border-r pr-3" }, [
          _c("span", [_vm._v("Sydney")])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }