var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "relative pt-16 pb-32 flex content-center items-center justify-center",
          staticStyle: { "min-height": "60vh" }
        },
        [
          _vm._m(0),
          _vm._m(1),
          _c(
            "div",
            {
              staticClass:
                "top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mb-px",
              staticStyle: { height: "70px", transform: "translateZ(0px)" }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "absolute bottom-0 overflow-hidden",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    preserveAspectRatio: "none",
                    version: "1.1",
                    viewBox: "0 0 2560 100",
                    x: "0",
                    y: "0"
                  }
                },
                [
                  _c("polygon", {
                    staticClass: "text-gray-200 fill-current",
                    attrs: { points: "2560 0 2560 100 0 100" }
                  })
                ]
              )
            ]
          )
        ]
      ),
      _c("section", { staticClass: "pb-4 px-3 bg-gray-200 -mt-32" }, [
        _c("div", { staticClass: "container mx-auto max-w-screen-lg" }, [
          _c("div", { staticClass: "flex flex-wrap" }, [
            _c("div", { staticClass: "w-full mx-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "relative px-12 py-10 min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded"
                },
                [_c("skills")],
                1
              )
            ])
          ])
        ])
      ]),
      _c("section", { staticClass: "pb-20 px-3 bg-gray-200" }, [
        _c("div", { staticClass: "container mx-auto max-w-screen-lg" }, [
          _c(
            "div",
            { staticClass: "grid sm:grid-cols-2 lg:grid-cols-3 gap-10" },
            _vm._l(_vm.filteredCompanies, function(company) {
              return _c("company-card", {
                key: company.id,
                attrs: { company: company }
              })
            }),
            1
          )
        ])
      ]),
      _c("news-letter-signup")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "absolute top-0 w-full h-full bg-center bg-cover",
        staticStyle: {
          "background-image":
            "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')"
        }
      },
      [
        _c("span", {
          staticClass: "w-full h-full absolute opacity-75 bg-black mb-1",
          attrs: { id: "blackOverlay" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container relative mx-auto" }, [
      _c("div", { staticClass: "items-center flex flex-wrap" }, [
        _c(
          "div",
          { staticClass: "w-full lg:w-6/12 px-4 ml-auto mr-auto text-center" },
          [
            _c("h1", { staticClass: "text-white font-semibold text-5xl" }, [
              _vm._v(" Find engineering teams hiring graduates. ")
            ]),
            _c("p", { staticClass: "mt-4 text-lg text-gray-300" }, [
              _vm._v(
                " Click on your skills and values to be with matched your ideal employer. "
              )
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }