<template>
  <div
    class="cursor-pointer bg-white rounded pt-8 shadow hover:shadow-lg hover:bg-gray-100"
    @click="showCompanyPage"
  >
    <div class="flex justify-center ">
      <img
        :src="`/logos/${company.slug.toLowerCase()}.png`"
        class="h-32 w-32 rounded-full border border-1 shadow-large"
      >
    </div>
    <div class="text-center px-3 pb-6 pt-2">
      <h3 class="text-black text-lg bold font-sans font-bold">
        {{ company.name }}
      </h3>
      <p class="mt-2 font-sans font-light text-grey-dark">
        {{ company.message }}
      </p>
    </div>
    <div
      class="flex bg-gray-300 justify-center text-grey-dark border-t-1 rounded-b py-4"
    >
      <div class="text-center mr-3 border-r pr-3">
        <!--<h2>2 Open Positions</h2>-->
        <span>Sydney</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyCard",
  props: ["company"],
  methods: {
    showCompanyPage() {
      this.$router.push({ path: `/company/${this.company.slug}` });
    },
  },
};
</script>
