<template>
  <div>
    <div
      class="relative pt-16 pb-32 flex content-center items-center justify-center"
      style="min-height: 60vh;"
    >
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="
          background-image: url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1267&amp;q=80');
        "
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-75 bg-black mb-1"
        />
      </div>

      <div class="container relative mx-auto">
        <div class="items-center flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <h1 class="text-white font-semibold text-5xl">
              Find engineering teams hiring graduates.
            </h1>
            <p class="mt-4 text-lg text-gray-300">
              Click on your skills and values to be with matched your ideal employer.
            </p>
          </div>
        </div>
      </div>

      <div
        class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mb-px"
        style="height: 70px; transform: translateZ(0px);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-gray-200 fill-current"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>
    </div>

    <section class="pb-4 px-3 bg-gray-200 -mt-32">
      <div class="container mx-auto max-w-screen-lg">
        <div class="flex flex-wrap">
          <div class="w-full mx-auto">
            <div
              class="relative px-12 py-10 min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded"
            >
              <!--<div
                class="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-10"
              >-->
              <skills />
              <!--<skills title="languages" color="green" />
                <skills title="cloud" color="blue" />
                <skills title="frontend" color="pink" />
                <skills title="bigdata" color="orange" />
                <skills title="culture" color="green" />
                <skills title="career" color="teal" />-->
              <!--</div>-->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-20 px-3 bg-gray-200">
      <div class="container mx-auto max-w-screen-lg">
        <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
          <company-card
            v-for="company in filteredCompanies"
            :key="company.id"
            :company="company"
          />
        </div>
      </div>
    </section>

    <news-letter-signup />
  </div>
</template>

<script>
import CompanyCard from "../components/CompanyCard";
import NewsLetterSignup from "../components/NewsLetterSignup";
import Skills from "../components/Skills";
import API from "@aws-amplify/api";
import { listings } from "@/graphql/queries";

export default {
  name: "Landing",
  components: {
    CompanyCard,
    NewsLetterSignup,
    Skills,
  },
  data() {
    return {
      listings: [],
    };
  },
  computed: {
    filteredCompanies() {
      if (this.$store.state.values.length) {
        let filtered = [];

        this.listings.forEach((item) => {
          let intersection = item.skills.filter((s) =>
            this.$store.state.values.includes(s)
          );
          if (intersection.length > 0) {
            item.matchedValues = intersection;
            filtered.push(item);
          }
        });

        return filtered.sort(
          (a, b) => a.matchedValues.length < b.matchedValues.length
        );
      } else {
        return this.listings;
      }
    },
  },
  async created() {
    this.getListings();
  },
  methods: {
    async getListings() {
      const l = await API.graphql({
        query: listings,
        variables: { input: this.profile },
        authMode: "AWS_IAM",
      });
      this.listings = l.data.listings;
    },
  },
};
// https://openbase.io/js/@aws-amplify/api-graphql/documentation
// https://docs.amplify.aws/lib/graphqlapi/authz/q/platform/ios
</script>
